export const COMPANY_NAME = '';
export const COMP_ADDRESS1 = '';
export const COMP_ADDRESS2 = '';
export const COMP_PHONE = '';
export const COMP_EMAIL = 'info@abc.co.uk';
export const BASE_URL = 'https://supervisesite.co.uk/admin/';
export const IMAGES_URL = 'https://supervisesite.co.uk/admin/public/';
// export const IMAGES_URL = 'http://localhost/supervisior_site/backend/public/';
export const API_BASE_URL = 'https://supervisesite.co.uk/admin/api/';
// export const API_BASE_URL = 'http://localhost/supervisior_site/backend/api/';
export const API_TOKEN = '1|JVqZ1a96H3IGItYZK4l7wSSqmv4kWVB7LFk20Yy72b66863d';
// export const API_TOKEN = '2|bX69Th7I5XdxVUXNEuMhCwrUoqnXgbGQ2djH2rI0d9f2af31';